function MainPage() {
    return (
        <main className="main-page">
            <div className="banner1"/>
            <div className="banner2"/>
            <div className="banner3"/>
            <div className="banner4"/>
        </main>
    )
}

export default MainPage;